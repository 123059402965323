import React from 'react'
import { Helmet } from 'react-helmet-async'
import { QUALTRICS_DOMAIN } from '@csat/common/constants/qualtrics'
import { ReducerFunc, useMessagePortal } from '../../hooks/useMessagePortal'

enum QUALTRICS_PORTAL_ACTIONS {
  UPDATE_TITLE = 'title',
}

interface QualtricsSurveyProps {
  surveyId: string
  embeddedData?: string
}

const QualtricsMessageReducer: ReducerFunc = (state, action) => {
  const { type, value } = action
  switch (type) {
    case QUALTRICS_PORTAL_ACTIONS.UPDATE_TITLE:
      return { ...state, pageTitle: value }
    default:
      return state
  }
}

const QualtricsSurvey: React.FC<QualtricsSurveyProps> = ({
  surveyId,
  embeddedData = '',
}) => {
  const { pageTitle = `` } = useMessagePortal(
    [window.location.origin, QUALTRICS_DOMAIN],
    QualtricsMessageReducer
  )

  return (
    <>
      <Helmet>
        <title>{pageTitle as string}</title>
      </Helmet>
      {/* eslint-disable-next-line react/iframe-missing-sandbox */}
      <iframe
        src={`${QUALTRICS_DOMAIN}/jfe/form/${surveyId}${embeddedData}`}
        title="Sainsbury's"
        width="100%"
        height="100%"
        frameBorder="0"
        data-testid="qualtrics_survey_iframe"
      />
    </>
  )
}

export default QualtricsSurvey
