import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import QualtricsSurvey from '../../components/Qualtrics/QualtricsSurvey'
import { getTrialSurveyId } from '../../components/Qualtrics/surveySplit'

const SurveyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()

  // Get survey ID that should be rendered based on Survey Simplification Trial logic
  const surveySimplificationTrialSurveyId = getTrialSurveyId(id)

  return (
    <QualtricsSurvey
      surveyId={surveySimplificationTrialSurveyId}
      embeddedData={location.search}
    />
  )
}

export default SurveyPage
