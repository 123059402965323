import React from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom'

const IndexPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()

  let surveyId = ''
  if (id) {
    surveyId += `SV_${id}`
  }
  if (location.search) {
    surveyId += `${location.search}`
  }

  return <Navigate to={`/survey/${surveyId}`} />
}

export default IndexPage
