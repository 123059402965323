import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSurveyTheme } from '../../hooks/useSurveyTheme'
import {
  Environments,
  getCurrentEnvironment,
} from '@csat/common/constants/environments'

const Head: React.FC = () => {
  const surveyTheme = useSurveyTheme()
  const environment = getCurrentEnvironment()

  const domainDataScript =
    surveyTheme.oneTrustDataDomainScript +
    (environment !== Environments.prod ? '-test' : '')

  return (
    <Helmet title={surveyTheme.pageTitle}>
      <script
        data-testid="onetrust_banner"
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        data-domain-script={domainDataScript}
      />
      <link rel="shortcut icon" href={surveyTheme.favicon} />
    </Helmet>
  )
}

export default Head
