import React from 'react'
import { Wrapper } from './Footer.styles'

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <button
        type="button"
        className="ln-c-link ln-c-link--bare ln-c-link--alpha ot-sdk-show-settings"
      >
        Manage Cookies
      </button>
    </Wrapper>
  )
}
export default Footer
