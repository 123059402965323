import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SurveyPage from '../../pages/SurveyPage'
import IndexPage from '../../pages/IndexPage'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/SV_:id" element={<IndexPage />} />
        <Route path="/survey/:id" element={<SurveyPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
