// Mappings for Survey Simplification Trial
// redirectSurveyId = Survey ID of new simplified survey
// redirectChance = Percentage of customers that should see this new survey
export const mappings: {
  [key: string]: { redirectSurveyId: string; redirectChance: number }
} = {
  // SV_6MtlOGAxp9i54GO: {
  //   redirectSurveyId: 'SV_0eXofGHBALFYUwC',
  //   redirectChance: 40,
  // },
  SV_cZnMPk9qqMRo5fL: {
    redirectSurveyId: 'SV_eUJobnU0FFfC3Y2',
    redirectChance: 33.3,
  },
}

export const expiryDays = 45
